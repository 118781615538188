export function animateLogo(tl) {
	document.querySelectorAll('#logo svg path.bolt, svg path.cloud, svg path.rocket, svg path.text, svg path.text2').forEach((el) => {
		el.classList.add('draw');
	});

	tl.from('#logo svg path.text', {drawSVG: 0, duration: 1, scale: .5, stagger: .1}, '0');
	tl.from('#logo svg path.rocket', {drawSVG: 0, duration: 1, stagger: .1}, '1');
	tl.to('#logo svg path.text', {duration: .5, fillOpacity: 1, stagger: .1, strokeOpacity: 0}, '1.2');
	tl.to('#logo svg path.rocket', {duration: .5, fillOpacity: 1, strokeOpacity: 0}, '2.2');
	tl.from('#logo svg path.cloud', {drawSVG: 0, duration: 1}, '2.5');
	tl.from('#logo svg path.bolt', {drawSVG: 0, duration: 1}, '3.5');
	tl.to('#logo svg path.cloud', {duration: 1, fillOpacity: 1, strokeOpacity: 0}, '2.8');
	tl.to('#logo svg path.bolt', {duration: .5, fill: '#ff5500', fillOpacity: 1, strokeOpacity: 0}, '3');
	tl.to('#logo svg .rocket-group', {transformOrigin: '50% 50%'}, '1.9');
	tl.to('#logo svg .rocket-group', {duration: 1, scaleX: -0.8, scaleY: 0.8}, '1.9');
	tl.to('#logo svg .rocket-group', {duration: 1, scaleX: 1, scaleY: 1}, '2.9');
	tl.fromTo('#logo svg path.bolt', {opacity: 0}, {duration: .1, opacity: 1, repeat: 4}, '3.5');
	tl.set('#logo svg path.cloud', {transformOrigin: 'bottom center'}, '3');
	tl.fromTo('#logo svg path.cloud', {scaleY: 1}, {duration: .1, scaleY: .9, repeat: 6}, '3.5');
	tl.from('#logo svg path.text2', {drawSVG: 0, duration: 1, stagger: .1}, '3.5');
	tl.to('#logo svg path.text2', {duration: .5, fillOpacity: 1, stagger: .1, strokeOpacity: 0}, '4');

	tl.set('#logo svg path.text3', {scale: 2, transformOrigin: '50% 50%'}, '5.6');
	tl.to('#logo svg path.text3', {duration: .1, ease: 'elastic.out(1,0.2)', scale: 1, stagger: .1}, '5.6');
	tl.to('#logo svg path.text3', {
		duration: .1,
		fill: '#fff',
		fillOpacity: 1,
		scale: 1,
		stagger: .1,
		strokeOpacity: 0
	}, '5.6');

	tl.to('#logo svg', {duration: .1, scale: .8}, '6.2');
	tl.to('#logo svg', {ease: 'elastic.out(2,0.2)', scale: 1}, '6.3');

	tl.set('#logo svg path.bolt', {scale: 2, transformOrigin: '50% 50%'}, '6.3');
	tl.to('#logo svg path.bolt', {duration: .5, ease: 'elastic.out(2,0.3)', fill: '#60c090', scale: 1}, '6.3');
	tl.to('#logo svg path.text3', {duration: .5, fill: null}, '6.3');
}