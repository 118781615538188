import axios from "axios";
import gsap from "gsap";
import DrawSVGPlugin from "gsap/DrawSVGPlugin";
import EasePack from "gsap/EasePack";

import loginFormHtml from "./html/loginForm.html";
import logoHtml from "./html/logo.html";
import { animateLogo } from "./animate-logo";

async function main() {

	// register gsap plugins
	gsap.registerPlugin(DrawSVGPlugin);
	gsap.registerPlugin(EasePack);

	// get app element
	const app = document.getElementById('app');
	if (!app) {
		return;
	}

	// form
	const form = (() => {
		const template = document.createElement('template');
		template.innerHTML = String(loginFormHtml);
		return template.content.firstElementChild;
	})();

	// logo
	const logo = (() => {
		const template = document.createElement('template');
		template.innerHTML = String(logoHtml);
		return template.content.firstElementChild;
	})();

	// construct ui
	form.prepend(logo);
	app.append(form);

	// animate logo
	const tl = gsap.timeline();
	animateLogo(tl);

	// form elements
	const feedback = form.querySelector('.feedback');
	const showPasswordButton = form.querySelector('.input-icon-show-password');
	const submitButton = form.querySelector('button[type="submit"]');
	const inputs = {
		email: form.querySelector('input[name="email"]'),
		password: form.querySelector('input[name="password"]'),
	};

	// regex
	const emailRegex = /^[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]+$/;

	// validator
	async function validate() {
		const data = {
			email: inputs.email.value.trim().toLowerCase(),
			password: inputs.password.value,
		};

		// email
		if (!data.email) {
			throw {
				element: inputs.email,
				message: "Please enter your email address."
			};
		}
		if (!data.email.match(emailRegex)) {
			throw {
				element: inputs.email,
				message: "The email you entered is not valid."
			};
		}

		// password
		if (!data.password) {
			throw {
				element: inputs.password,
				message: "Please enter your password."
			};
		}

		return data;
	}

	// feedback functions
	function hideError() {
		feedback.classList.add('hidden');
		feedback.innerHTML = '';
	}
	function showError(error) {
		feedback.innerHTML = error.message;
		feedback.classList.remove('hidden');
		if (error.element) {
			error.element.classList.add('has-error');
		}
	}

	// input focus event handler
	Object.values(inputs).forEach((input) => {
		input.addEventListener('blur', () => {
			const wrapper = input.closest('.input-wrapper');
			if (wrapper) {
				wrapper.classList.remove('active');
			}
		});
		input.addEventListener('focus', () => {
			const wrapper = input.closest('.input-wrapper');
			if (wrapper) {
				wrapper.classList.add('active');
			}
			if (input.classList.contains('has-error')) {
				input.classList.remove('has-error');
				hideError();
			}
		});
	});

	// show password button click event handler
	showPasswordButton.addEventListener('click', () => {
		if ('password' === inputs.password.type) {
			inputs.password.type = 'text';
		} else {
			inputs.password.type = 'password';
		}
	});

	// form submit event handler
	form.addEventListener('submit', async (e) => {
		e.preventDefault();
		e.stopPropagation();
		let data;
		try {
			data = await validate();
		} catch (error) {
			showError(error);
			return;
		}
		try {
			const res = await axios.post('/api/auth', data, {
				responseType: 'json',
				transformResponse: [function(data, headers, status) {
					try {
						data = JSON.parse(data);
					} catch (error) {
						throw new Error("Response does not contain valid JSON.");
					}
					if (200 === status && (!('accessToken' in data) || !data.accessToken)) {
						throw new Error("Response (200) does not contain access token.");
					}
					if (422 === status && (!('message' in data) || !data.message)) {
						throw new Error("Response (422) does not contain message.");
					}
					return data;
				}],
				validateStatus: function(status) {
					return [200, 422].includes(status);
				},
			});
			if (200 === res.status) {
				window.location.href = '/';
			}
			if (422 === res.status) {
				showError({ message: res.data.message });
			}
		} catch (error) {
			showError({ message: "Unable to log in at this time." });
		}
	});

}

main().catch((err) => {
	console.log(err);
});